import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useNavigate, Link } from 'react-router-dom'
import { validateForm } from '../utils/validateForm'
import { subscribe } from "../utils/pubsub"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import Card from '../ui-elements/Card'
import Text from '../formfields/Text'
import Number from '../formfields/Number'
import Button from '../formfields/Button'
import EnabledDisabled from '../formfields/EnabledDisabled'
import Dropdown from '../formfields/Dropdown'
import Checkbox from '../formfields/Checkbox'
import SubNavigation from './SubNavigation'




type Props = {

}

type OptionsProps = {
  userData: ObjectStringKeyAnyValue | null
  formFields: ObjectStringKeyAnyValue
  fieldName: string
  setFormFields: React.Dispatch<React.SetStateAction<any>>
}


function Options({
  setFormFields,
  formFields,
  fieldName,
  userData
}: OptionsProps) {


  const thisField = formFields[fieldName]
  const optionValues = [{
    "value": 'Everyone',
    "label": 'Everyone'
  }, {
    "value": 'Owners',
    "label": 'Owners'
  }, {
    "value": 'Admins',
    "label": 'Admins'
  },
    // {
    //   "value": `${userData ? userData['cognito:username'] : 'none'}`,
    //   "label": `Just me`
    // }
  ]

  const labels: ObjectStringKeyAnyValue = {
    "checkPeriodEndNoIssues": 'Check period has been completed with no issues',
    "checkPeriodEndWithIssues": 'Check period has been completed with issues',
    "checkPeriodIncompletePrewarn": 'Pre warning that the check period is incomplete',
    "immediateIssueWarning": 'Immediate notification whenever an issue is raised'
  }


  const handleCheckbox = (key: string, isChecked: any) => {
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    newFieldValues[key]['enabled'] = isChecked
    if (!isChecked) {
      newFieldValues[key]['recipients'] = null
    }
    delete newFieldValues[key]['isValid']
    setFormFields(newFieldValues)
  }

  const handleRadio = (key: string, value: any) => {
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    newFieldValues[key]['recipients'] = value
    setFormFields(newFieldValues)
  }


  return <div className={`w-full flex flex-row items-start gap-4`}>

    <EnabledDisabled
      handleChange={handleCheckbox}
      fieldName={fieldName}
      enabled={thisField.enabled ? true : false}
    />



    <div className={`w-full flex flex-col justify-between gap-2`}>
      <p className={`font-bold`}>{labels[fieldName]}</p>

      {thisField.enabled && optionValues && <div className={`flex flex-row gap-2 text-sm`}>
        <p className={``}>Send these notifications to:</p>
        <div className={`flex flex-row gap-3 text-sm`}>
          {optionValues.map((option, index) => {
            return <label
              key={index}
              className={`flex flex-row gap-1 items-center`}>
              <input
                type={'radio'}
                key={index}
                name={fieldName}
                value={option.value}
                checked={thisField.recipients === option.value ? true : false}
                onChange={() => handleRadio(fieldName, option.value)}
              />
              {option.label}</label>
          })}</div>
      </div>}

      {thisField && thisField['isValid'] === false && <p
        className={`text-xs text-red-700`}>Please select who should receive the notifications</p>}
    </div>


  </div>
}




function Notifications({

}: Props) {


  const {
    tableData,
    setTableData,
    socket,
    sendMessageToWebsocket,
    idToken,
    accessToken,
    orgSettings,
    currentOrganisation,
    userData,
    setShowModal
  } = useContext(GlobalContext)


  const [savingProgress, setSavingProgress] = useState(false)
  const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue | null>(null)


  const defaults: ObjectStringKeyAnyValue = {
    "checkPeriodEndNoIssues": {
      "enabled": true,
      "recipients": `Admins`
    },
    "checkPeriodEndWithIssues": {
      "enabled": true,
      "recipients": `Admins`
    },
    "checkPeriodIncompletePrewarn": {
      "enabled": true,
      "recipients": `Admins`
    },
    "immediateIssueWarning": {
      "enabled": true,
      "recipients": `Admins`
    }
  }


  useEffect(() => {
    const newFormFields: ObjectStringKeyAnyValue = {}
    Object.keys(defaults).forEach((key) => {
      if (tableData && tableData.Organisation && tableData.Organisation.Notifications && tableData.Organisation.Notifications[key]) {
        newFormFields[key] = tableData.Organisation.Notifications[key]
      } else {
        newFormFields[key] = defaults[key]
      }
    })
    setFormFields(newFormFields)
  }, [tableData])

  const validateForm = () => {
    console.log('validating form')
    if (formFields) {
      let canSubmit = true
      const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
      Object.keys(formFields).forEach((fieldName: string) => {
        const thisField = formFields[fieldName]
        console.log(fieldName)
        console.log(thisField)
        if (thisField.enabled === true && !thisField.recipients) {
          newFieldValues[fieldName]['isValid'] = false
          canSubmit = false
        } else {
          newFieldValues[fieldName]['isValid'] = true
        }
      })
      setFormFields(newFieldValues)
      if (canSubmit) {
        console.log('ok')
        saveData()
      } else {
        console.log('error')
      }
    }
  }

  const saveData = () => {
    setShowModal({ "spinner": 'Saving...' })
    const values = { ...formFields }
    const payload = JSON.stringify({
      action: "notificationPreferences",
      values: values
    })
    sendMessageToWebsocket(payload)

    const unsubscribeUpdate = subscribe("notificationPreferencesResponse", data => {
      setShowModal(null)
      unsubscribeUpdate()
    })
  }


  return <Card>
    <h3 className={`text-xl font-bold text-brandblue`}>Notifications</h3>


    {tableData && userData && userData['cognito:username'] ? <div
      className={`w-full flex flex-col items-start gap-5`}>


      <p>Set notifications for when:</p>
      <div
        className={`w-full flex flex-col gap-4`}>
        {formFields && Object.keys(formFields).map((fieldName, index) => {
          return <Options
            key={index}
            formFields={formFields}
            setFormFields={setFormFields}
            userData={userData}
            fieldName={fieldName}
          />
        })}
      </div>
      <Button
        text={'Save'}
        onClick={validateForm}
        // size={'big'}
        fullwidth={false}
      />


    </div>
      :
      <Spinner><p>Loading...</p></Spinner>}



  </Card>
}
export default Notifications