import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../GlobalContext'
import RedAlert from '../../ui-elements/RedAlert'
import { Link, useParams } from 'react-router-dom'


type Props = {
    questionsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    questionsFormErrors: ObjectStringKeyAnyValue
    setQuestionsFormFields: React.Dispatch<React.SetStateAction<any>>
}


type IndividualCustomDataFormatProps = {
    selectedId: string | null
    setSelectedId: React.Dispatch<React.SetStateAction<any>>
    thisItem: ObjectStringKeyAnyValue
    disabled: boolean
}

function IndividualCustomDataFormat({
    selectedId,
    setSelectedId,
    thisItem,
    disabled
}: IndividualCustomDataFormatProps) {


    return <div
        className={`
      ${!disabled && selectedId === thisItem.Id && ' bg-brandblue border-brandblue text-white '}
      ${!disabled && selectedId !== thisItem.Id && ' bg-white border-gray-400'}
      ${disabled ? ' border-gray-400 bg-gray-300 text-gray-700 ' : ' hover:opacity-80 cursor-pointer '}
      rounded py-2 px-4 border`}
        onClick={() => { !disabled && setSelectedId(thisItem.Id) }}
    >
        <p className={`font-bold`}>{thisItem.RegexName}</p>
    </div>

}


function SelectCustomDataFormat({
    questionsFormFields,
    thisChecklist,
    questionsFormErrors,
    setQuestionsFormFields
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)

    const { checklistId, questionId } = useParams()




    const handleChange = (value: string) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...questionsFormFields }
        newFieldValues['CustomDataFormatId'] = value
        setQuestionsFormFields(newFieldValues)
    }

    return <div className={`flex flex-col gap-2`}>
        <h3 className={`font-righteous text-brandblue text-lg`}>Select custom data format:</h3>

        {Object.keys(tableData['CustomDataFormats']).length === 0 &&
            <RedAlert alignment='left'><p>No custom data formats have been created yet. Please <Link to='/custom-data-builder' className={`underline m-0`}>create one</Link> first.</p></RedAlert>
        }

        {questionsFormErrors['CustomDataFormatId'] && <RedAlert alignment='left'>{questionsFormErrors['CustomDataFormatId']}</RedAlert>}


        {tableData && Object.keys(tableData['CustomDataFormats']).length > 0 && tableData['CustomDataFormats'] && Object.keys(tableData['CustomDataFormats']).length > 0 && <div className={`flex flex-row flex-wrap gap-2 mb-4`}>
            {Object.keys(tableData['CustomDataFormats']).map((Id, index) => {
                const thisItem = tableData['CustomDataFormats'][Id]

                return <IndividualCustomDataFormat
                    key={index}
                    selectedId={questionsFormFields['CustomDataFormatId']}
                    setSelectedId={handleChange}
                    thisItem={thisItem}
                    disabled={false}
                />

            })}



        </div>}

    </div>
}



export default SelectCustomDataFormat