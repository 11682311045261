
import React, { useEffect, useState, Children, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"
import YellowAlert from './YellowAlert'
import { getChecklistsThatUseThisItem } from '../utils/getAllChecklistsThatUseThisItem'

type Props = {
    keyName: string,
    itemId: string
}

function AssociatedChecklists({
    keyName,
    itemId
}: Props) {

    const {
        tableData
    } = useContext(GlobalContext)


    const [showAll, setShowAll] = useState(false)


    const checklistsThatUseThisFormat = getChecklistsThatUseThisItem(keyName, itemId, tableData)



    return <div>


        {!showAll && checklistsThatUseThisFormat.length > 0 &&
            <div 
            className={`hover:opacity-80 cursor-pointer`}
            onClick={() => setShowAll(true)}
            >
                <YellowAlert icon={true} size={`small`} alignment={`left`}>
                    Used by {checklistsThatUseThisFormat.length} checklist{checklistsThatUseThisFormat.length === 1 ? '' : 's'}
                </YellowAlert>
            </div>}



        {showAll && checklistsThatUseThisFormat.length > 0 &&
            <div 
            className={`hover:opacity-80 cursor-pointer`}
            onClick={() => setShowAll(false)}
            >
                <YellowAlert icon={true} size={`small`} alignment={`left`}>
                    <div>
                        <p>Used by following checklist{checklistsThatUseThisFormat.length === 1 ? '' : 's'}:</p>
                        <p>
                            {checklistsThatUseThisFormat.map((checklist: any, index: number) => {
                                return <><Link
                                    className={`underline`}
                                    to={`/checklists/${checklist.checklistId}`}>{checklist.checklistName}</Link>{index + 1 < checklistsThatUseThisFormat.length ? ', ' : ' '}</>
                            })}</p>
                    </div>
                </YellowAlert>
            </div>}

    </div>
}
export default AssociatedChecklists