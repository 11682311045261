

import React, { useContext, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import CloudformationOutputs from '../CloudformationOutputs.json'
import Modal from '../ui-elements/Modal'

type Props = {
  questionAnswer: any
  isTemperature?: boolean
  size?: 'normal' | 'big'
  question?: any
}

function FormatQuestionAnswer({
  questionAnswer,
  isTemperature = false,
  size = 'normal',
  question
}: Props) {

  const {
    tableData,
    currentOrganisation
  } = useContext(GlobalContext)

  const [showPhotoModal, setShowPhotoModal] = useState(false)

  const classString = `
  ${size === 'normal' ? ' text-sm ' : ''}
  ${size === 'big' ? ' text-xl font-righteous text-brandblue ' : ''}
  `

  let unit = ''
  if (question && question.MeasurementUnit) {
    unit = ` ${question.MeasurementUnit}`
  } else if (isTemperature){
    unit = ' °C'
  }

  return <span className={classString}>



    {questionAnswer.Answer && <>

      {questionAnswer.Answer instanceof Array &&
        <>{questionAnswer.Answer.map((option: string, index: number) => {
          return `${option}${index + 1 < questionAnswer.Answer.length ? ', ' : ''}`
        })}</>}


      {questionAnswer.Answer instanceof Object && !(questionAnswer.Answer instanceof Array) &&
        <>{Object.keys(questionAnswer.Answer).map((keyName: string, index: number) => {
          return `${keyName}: ${questionAnswer.Answer[keyName] || ''}${index + 1 < Object.keys(questionAnswer.Answer).length ? ', ' : ''}`
        })}</>}


      {typeof questionAnswer.Answer === 'string' ? `${questionAnswer.Answer}${unit}` : ''}

   

    </>}
  </span>
}

export default FormatQuestionAnswer