import React, { useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom"
import CloudformationOutputs from '../CloudformationOutputs.json'
import FormatQuestionAnswer from './FormatQuestionAnswer'
import Button from '../formfields/Button'
import { GlobalContext } from '../GlobalContext'
import Card from '../ui-elements/Card'
import GreenAlert from '../ui-elements/GreenAlert'
import Modal from '../ui-elements/Modal'
import RedAlert from '../ui-elements/RedAlert'
import Spinner from '../ui-elements/Spinner'
import YellowAlert from '../ui-elements/YellowAlert'
import { getDate } from '../utils/getDate'
import { getStartAndEndTimestampsForDay } from '../utils/getStartAndEndTimestampsForDay'
import ListAnswerNotes from './ListAnswerNotes'
import FormatChecklistTimeWindow from './FormatChecklistTimeWindow'
import QuestionSummary from './QuestionSummary'
import { sortAllQuestionAnswersForCheckPeriod } from '../utils/sortAllQuestionAnswersForCheckPeriod'
import QuestionSummaryDetail from './QuestionSummaryDetail'
import { subscribe } from '../utils/pubsub'
import { calculateRepeatAnswers } from './checklist-utils/calculateRepeatAnswers'

type Props = {
}

type ChecklistPeriodsProps = {
  thisCheckPeriod: ObjectStringKeyAnyValue
  // thisChecklistQuestions: ObjectStringKeyAnyValue[] | null
  // sortedQuestionAnswers: ObjectStringKeyAnyValue[] | null
  setSavingProgress: React.Dispatch<React.SetStateAction<boolean>>
}





function ChecklistPeriods({
  thisCheckPeriod,
  // sortedQuestionAnswers,
  // thisChecklistQuestions,
  setSavingProgress
}: ChecklistPeriodsProps) {

  const {
    tableData,
    sendMessageToWebsocket
  } = useContext(GlobalContext)



  const { checklistId, year, month, day } = useParams()
  const [followUpQuestionIds, setFollowUpQuestionIds] = useState<Array<string> | null>(null)
  const [thisChecklist, setThisChecklist] = useState<ObjectStringKeyAnyValue | null>(null)
  const [thisChecklistQuestions, setThisChecklistQuestions] = useState<null | Array<ObjectStringKeyAnyValue>>(null)
  const [sortedQuestionsAndAnswers, setSortedQuestionsAndAnswers] = useState<null | Array<ObjectStringKeyAnyValue>>(null)
  const customTemplateFileId = tableData && checklistId && tableData.Checklists && tableData.Checklists[checklistId].CustomTemplateFileId ? tableData.Checklists[checklistId].CustomTemplateFileId : null



  useEffect(() => {
    //console.log(`🚨 running update`)
    if (thisCheckPeriod && tableData) {
      const checkPeriodId = thisCheckPeriod.Id

      if (
        tableData.Checklists
        && tableData.ChecklistPeriods
        && tableData.ChecklistPeriods[checkPeriodId]
        && tableData.ChecklistPeriods[checkPeriodId]['ChecklistId']
        && tableData.Checklists[tableData.ChecklistPeriods[checkPeriodId]['ChecklistId']]
      ) {

        const currentChecklist = tableData.Checklists[tableData.ChecklistPeriods[checkPeriodId]['ChecklistId']]
        setThisChecklist(currentChecklist)


        const allQuestionAnswersArray = sortAllQuestionAnswersForCheckPeriod(checkPeriodId, tableData)
        setSortedQuestionsAndAnswers(allQuestionAnswersArray)

        // get questions for this checklist
        // filter follow up questions
        // and sort by order
        if (tableData['ChecklistQuestions']) {
          let questionsArray: any[] = []
          for (const parentQuestionId in tableData['ChecklistQuestions']) {
            const parentQuestion = tableData['ChecklistQuestions'][parentQuestionId]
            if (
              parentQuestion.ChecklistId === currentChecklist.Id &&
              (!parentQuestion['parentQuestionId'] && !parentQuestion['parentActionId'])
            ) {
              questionsArray.push(parentQuestion)

              for (const followUpQuestionId in tableData['ChecklistQuestions']) {
                const followUpQuestion = tableData['ChecklistQuestions'][followUpQuestionId]
                if (
                  followUpQuestion.ChecklistId === currentChecklist.Id &&
                  followUpQuestion['parentQuestionId'] === parentQuestionId
                ) {
                  questionsArray.push(followUpQuestion)

                }
              }

            }
          }

          questionsArray.sort((a, b) => parseInt(a.QuestionOrder) - parseInt(b.QuestionOrder))
          setThisChecklistQuestions(questionsArray)
        }

      }
    }
  }, [thisCheckPeriod, tableData])

  const fetchCustomChecklistResults = () => {
    setSavingProgress(true)
    const payload = JSON.stringify({
      action: "document",
      subAction: "get_results",
      checklistId: checklistId,
      checkPeriodId: thisCheckPeriod.Id,
      customTemplateFileId: customTemplateFileId
    })
    sendMessageToWebsocket(payload)

    const unsubscribe = subscribe("customTemplateUploadResponse", data => {
      setSavingProgress(false)
      window.location.href = data.downloadUrl
      unsubscribe()
    })
  }





  return <div>
    <div className={`w-full flex flex-row gap-2 justify-between items-center`}>
      <h4 className={`mt-3 font-righteous text-2xl font-brandblue`}>Check period:

        <FormatChecklistTimeWindow thisCheckPeriod={thisCheckPeriod} />

      </h4>

      {customTemplateFileId && <Button
        text={'Download custom results'}
        onClick={fetchCustomChecklistResults}
        variant={'primary'}
        size={'small'}
        fullwidth={false}
      />}
    </div>



    {thisCheckPeriod && sortedQuestionsAndAnswers && thisChecklistQuestions && thisChecklistQuestions.length > 0 &&
      <div className={`mt-2 w-full flex flex-col gap-2`}>
        {thisChecklistQuestions.map((question: ObjectStringKeyAnyValue, index: number) => {
          const answersForThisQuestion = sortedQuestionsAndAnswers.filter((answer: ObjectStringKeyAnyValue) => answer.QuestionId === question.Id)
          const parentQuestion = tableData.ChecklistQuestions[question.parentQuestionId]
          const { maxRepeatAnswersForThisQuestion, repeatAnswersForThisQuestion } = calculateRepeatAnswers(answersForThisQuestion, question)

          if (answersForThisQuestion.length > 0) {

            return <div key={index} className={`w-full flex flex-col gap-2`}>

              {/* <div className={`w-full flex flex-col`}>
              {question.QuestionFormat !== 'FridgeTemperature' && <h5 className={`text-lg font-righteous text-brandblue`}>{question.QuestionText}</h5>}
              {question.QuestionFormat === 'FridgeTemperature' && <h5 className={`text-lg font-righteous text-brandblue`}>Temperature check for fridge: {question.FridgeId} </h5>}

              {parentQuestion && <p className={`text-xs italic`}>Followup question for: "{parentQuestion.QuestionText}"</p>}
              </div> */}

              {answersForThisQuestion.map((answer: ObjectStringKeyAnyValue, index: number) => {
                const question = tableData.ChecklistQuestions[answer.QuestionId]
                return <QuestionSummaryDetail
                  key={index}
                  questionAnswer={answer}
                  checkPeriodId={thisCheckPeriod.Id}
                  question={question}
                  answerNumber={index + 1}
                  answersForThisQuestion={answersForThisQuestion}
                  sortedQuestionsAndAnswers={sortedQuestionsAndAnswers}
                  parentQuestion={parentQuestion}
                />
              })}



            </div>
          }
        })}
      </div>}



    {/* <div className={`w-full flex flex-col gap-2`}>


      {sortedQuestionAnswers && sortedQuestionAnswers.length === 0 && <RedAlert icon={true}>No answer data found for this checklist</RedAlert>}


      {sortedQuestionAnswers && sortedQuestionAnswers.length > 0 && <div
        className={`mt-3 w-full flex flex-col gap-2`}>
        {sortedQuestionAnswers.map((answer: ObjectStringKeyAnyValue, index: number) => {
          const question = tableData.ChecklistQuestions[answer.QuestionId]
          return <QuestionSummaryDetail
            key={index}
            questionAnswer={answer}
            checkPeriodId={thisCheckPeriod.Id}
            question={question}
            sortedQuestionAnswers={sortedQuestionAnswers}
          />
        })}

      </div>
      }




    </div> */}
  </div>
}

function ChecklistResultsDay({
}: Props) {

  const {
    tableData,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  const navigate = useNavigate()
  const { checklistId, year, month, day } = useParams()
  const [displayDate, setDisplayDate] = useState<string | number | null>(null)
  const [checklistPeriods, setChecklistPeriods] = useState<null | ObjectStringKeyAnyValue>(null)
  // const [thisChecklistQuestions, setThisChecklistQuestions] = useState<null | Array<ObjectStringKeyAnyValue>>(null)
  const [savingProgress, setSavingProgress] = useState(false)
  const [hasDataBeenFetched, setHasDataBeenFetched] = useState(false)
  const displayTimeZoneDifferenceWarning = tableData && tableData.Organisation && tableData.Organisation.OrganisationTimeZone && Intl.DateTimeFormat().resolvedOptions().timeZone !== tableData.Organisation.OrganisationTimeZone

  // tableData && console.log(tableData['ChecklistPeriods'])

  useEffect(() => {
    if (tableData && month && year && day) {
      if (!hasDataBeenFetched) {
        fetchData()
      }
    }
  }, [tableData, year, month, day, hasDataBeenFetched])

  useEffect(() => {
    // refetch data if date changes
    setHasDataBeenFetched(false)
  }, [displayDate])

  useEffect(() => {
    if (year && month && day) {
      const dateString = `${year}-${`${month}`.padStart(2, '0')}-${`${day}`.padStart(2, '0')}`
      setDisplayDate(dateString)
    } else {
      const dateString = getDate(Date.now(), 'datePickerFormat')
      setDisplayDate(dateString)
    }
  }, [year, month, day])


  useEffect(() => {
    if (tableData && displayDate) {
      // set checklist periods
      const timeZone = tableData.Organisation.OrganisationTimeZone || 'Europe/London'
      const { startTimestamp, endTimestamp } = getStartAndEndTimestampsForDay(displayDate, timeZone)
      const newChecklistPeriods: ObjectStringKeyAnyValue = {}

      if (tableData['ChecklistPeriods']) {
        for (const key in tableData['ChecklistPeriods']) {
          const thisCheckPeriod = tableData['ChecklistPeriods'][key]

          if (
            thisCheckPeriod['ChecklistId'] === checklistId
            && parseInt(thisCheckPeriod['StartTimeStamp']) >= startTimestamp
            && parseInt(thisCheckPeriod['StartTimeStamp']) <= endTimestamp
          ) {
            // console.log(`
            // check period ${key}
            // ${getDate(startTimestamp)} - ${getDate(endTimestamp)}
            // ${getDate(thisCheckPeriod['StartTimeStamp'])} - ${getDate(thisCheckPeriod['EndTimeStamp'])}
            // `)
            newChecklistPeriods[key] = tableData['ChecklistPeriods'][key]
          }
        }
        setChecklistPeriods(newChecklistPeriods)
      }

      // set questions
      // if (tableData['ChecklistQuestions']) {
      //   let questionsArray: any[] = Object.values(tableData['ChecklistQuestions']).filter((question: any) => question.ChecklistId === checklistId && !question.parentQuestionId && !question.parentActionId)
      //   questionsArray.sort((a, b) => parseInt(a.QuestionOrder) - parseInt(b.QuestionOrder))
      //   setThisChecklistQuestions(questionsArray)
      // }

    }
  }, [tableData, displayDate])




  const fetchData = () => {
    const payload = JSON.stringify({
      "action": "checklists",
      "subAction": "fetchChecklistInfoByTimePeriod",
      "checklistId": checklistId,
      "month": month,
      "year": year,
      "day": day
    })
    console.log('🎣 fetching...')
    sendMessageToWebsocket(payload)
    setHasDataBeenFetched(true)
  }

  const changeDate = (dateString: string) => {
    const dateParts = dateString.split('-')
    const year = parseInt(dateParts[0])
    const month = parseInt(dateParts[1])
    const day = parseInt(dateParts[2])
    navigate(`/checklists/${checklistId}/results/${year}/${month}/${day}`)
  }




  return <div className={`w-full flex flex-col items-center`}>

    {savingProgress && <Modal
      showCloseButton={true}
      setShowModal={setSavingProgress}
    ><Spinner><p>Downloading...</p></Spinner>
    </Modal>}

    <div className={`w-full bg-white flex flex-col items-center`}>
      <div className={`max-w-3xl w-full p-5 flex flex-row gap-4 items-start  justify-between`}>
        <div>
          <h3 className={`font-righteous text-3xl font-brandblue`}>
            {checklistId && tableData?.Checklists[checklistId].ChecklistName ? tableData.Checklists[checklistId].ChecklistName : "Untitled Checklist"}
          </h3>
          <div className={`mt-3 w-full flex flex-row gap-4 items-center`}>
            <p>Select date:</p>
            <input
              className={`rounded px-2 py-1 bg-gray-200 border border-gray-400 text-gray-600 text-sm`}
              type={'date'}
              onChange={(e) => changeDate(e.target.value)}
              value={displayDate || ''}
            />
          </div>
        </div>

        <div className={`flex flex-col gap-1`}>
          <Button
            text={'Month view'}
            internalLinkUrl={`/checklists/${checklistId}/results/${year}/${month}`}
            variant={'primary'}
            size={'small'}
            fullwidth={false}
          />



        </div>

      </div>

    </div>


    {!hasDataBeenFetched && <div className={`max-w-3xl w-full p-5 flex flex-col gap-4`}><Spinner>Loading...</Spinner></div>}

    {hasDataBeenFetched && <div className={`max-w-3xl w-full p-5 flex flex-col gap-4`}>

    {displayTimeZoneDifferenceWarning && <YellowAlert>Times are displayed in time zone: {tableData.Organisation.OrganisationTimeZone}</YellowAlert>}

      
      {checklistId &&
        tableData &&
        tableData.Checklists &&
        tableData.Checklists[checklistId] ?
        <>




          {checklistPeriods && Object.keys(checklistPeriods).length === 0 && <RedAlert>No check periods for this date</RedAlert>}

          {checklistPeriods && Object.keys(checklistPeriods).length > 0 && <div className={`w-full flex flex-col gap-4`}>
            {Object.keys(checklistPeriods).map((key, index) => {
              const thisCheckPeriod = checklistPeriods[key]
              // const sortedQuestionAnswers = sortAllQuestionAnswersForCheckPeriod(key, tableData)
              return <ChecklistPeriods
                key={index}
                thisCheckPeriod={thisCheckPeriod}
                // thisChecklistQuestions={thisChecklistQuestions}
                // sortedQuestionAnswers={sortedQuestionAnswers}
                setSavingProgress={setSavingProgress}
              />
            })}
          </div>}


        </>
        :
        <div className={`max-w-3xl w-full p-5 flex flex-col gap-2`}>
          <RedAlert>Checklist ID: {checklistId} not found</RedAlert>
        </div>
      }
    </div>}
  </div>
}
export default ChecklistResultsDay