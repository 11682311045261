const { DateTime } = require("luxon");


export function getStartAndEndTimestampsForDay(dateString, timeZone) {
  const startTimestamp = DateTime.fromISO(dateString, { zone: timeZone }).startOf("day").toMillis();
  const endTimestamp = DateTime.fromISO(dateString, { zone: timeZone }).endOf("day").toMillis();
  
  return {
        startTimestamp: startTimestamp,
        endTimestamp: endTimestamp
    };
    
}


