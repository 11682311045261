import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"

import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import Spinner from '../ui-elements/Spinner'
import { useParams, useNavigate } from 'react-router-dom'
import DeleteButton from '../formfields/DeleteButton'
import EnableOrDisableChecklist from './EnableOrDisableChecklist'
import Modal from '../ui-elements/Modal'

type Props = {
}

function ListOfChecklists({
}: Props) {

  const {
      tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    checklistSetupFields,
    setChecklistSetupFields
  } = useContext(GlobalContext)

  const { checklistId } = useParams()
  const navigate = useNavigate()
  const [savingProgress, setSavingProgress] = useState(false)
  const currentDate = new Date()


  const deleteChecklist = (checklistId: string) => {
    if (checklistId) {
      setSavingProgress(true)
      const payload = JSON.stringify({
        action: "checklists",
        subAction: "deleteChecklist",
        checklistId: checklistId
      })
      sendMessageToWebsocket(payload)
      const unsubscribe = subscribe("checklistDeletedResponse", data => {
        setSavingProgress(false)
        unsubscribe()
      })
    }
  }


  return <div className={`mt-2`}>



    {tableData && tableData.Checklists &&
      Object.keys(tableData.Checklists).length === 0 && <Card>No checklists found</Card>}

    {tableData && tableData.Checklists &&
      Object.keys(tableData.Checklists).length > 0 && <div>

        <p>{Object.keys(tableData.Checklists).length} checklist{Object.keys(tableData.Checklists).length > 1 && 's'} found</p>

        <div className={`mt-2 flex md:flex-row flex-col flex-wrap gap-2`}>
          {Object.keys(tableData.Checklists).map((checklistKey, index) => {
            const thisChecklist = tableData.Checklists[checklistKey]
            return <Card key={index}>
                <div className={`md:w-80 h-full w-full flex flex-col gap-4 justify-between items-center`}>
                  <div className={`w-full text-xs`}>
                    <h4 className={`text-base font-righteous text-brandblue text-lg`}>{thisChecklist['ChecklistName'] || 'unnamed checklist'}</h4>
                    <p>{
                      thisChecklist['Schedule'] &&
                        thisChecklist['Schedule']['CheckWindows'] &&
                        Object.keys(thisChecklist['Schedule']['CheckWindows']).length > 0 ?
                        `${Object.keys(thisChecklist['Schedule']['CheckWindows']).length} check window(s)` :
                        'No check windows'
                    }
                    </p>

                    <p>
                      {
                        tableData['ChecklistQuestions'] &&
                        Object.values(tableData['ChecklistQuestions']).filter((question: any) => question.ChecklistId === checklistKey).length} question(s)
                  </p>

                    <EnableOrDisableChecklist
                      thisChecklist={thisChecklist}
                    // savingProgress={savingProgress}
                    // setSavingProgress={setSavingProgress}
                    />
                  </div>
                  <div className={`w-full flex flex-row gap-2`}>
                    <DeleteButton
                                  deleteFunction={deleteChecklist}
                      functionParams={checklistKey}
                      fullwidth={true}
                      //size={`small`}
                    />
                    {/* <Button
                    internalLinkUrl={thisChecklist['Id']}
                    text={`duplicate`}
                    variant={`gray`}
                    disabled={true}
                    fullwidth={false}
                  /> */}
                    <Button
                                  internalLinkUrl={`/checklists/${thisChecklist['Id']}/results/${currentDate.getFullYear()}/${currentDate.getMonth() + 1}`}
                      text={`Results`}
                      variant={`primary`}
                      fullwidth={true}
                      //size={`small`}
                    />
                    <Button
                                  internalLinkUrl={`/checklists/${thisChecklist['Id']}`}
                      text={`View`}
                      variant={`primary`}
                      fullwidth={true}
                      //size={`small`}
                    />
                  </div>
                </div>
              </Card>
        
          })}
        </div>
      </div>
    }



  </div>
}
export default ListOfChecklists