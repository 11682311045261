import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useNavigate, Link } from 'react-router-dom'
import { validateForm } from '../utils/validateForm'
import { subscribe } from "../utils/pubsub"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import Card from '../ui-elements/Card'
import Text from '../formfields/Text'
import Number from '../formfields/Number'
import Button from '../formfields/Button'
import EnabledDisabled from '../formfields/EnabledDisabled'
import Dropdown from '../formfields/Dropdown'
import Checkbox from '../formfields/Checkbox'




type Props = {

}



function LoginOptions({

}: Props) {


  const {
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    setShowModal
  } = useContext(GlobalContext)


  const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>({})
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    setFormFields({})
  }, [currentOrganisation])


  useEffect(() => {
    if (
      tableData &&
      tableData.Organisation &&
      tableData.Organisation.loginOptions
    ) {
      const newFieldValues: ObjectStringKeyAnyValue = { ...tableData.Organisation.loginOptions }
      setFormFields(newFieldValues)
    }
  }, [tableData])







  const handleCheckbox = (key: string, checked: boolean) => {
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    if (checked) {
      newFieldValues[key] = {}
    } else {
      delete newFieldValues[key]
    }
    setFormFields(newFieldValues)
  }


  const handleSelect = (value: string) => {
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    if (value) {
      if (!newFieldValues['Guest']) {
        newFieldValues['Guest'] = { 'timeout': value }
      } else {
        newFieldValues['Guest']['timeout'] = value
      }
    } else {
      delete newFieldValues['Guest']['timeout']
    }
    setFormFields(newFieldValues)
  }


  const saveData = () => {
    if (formFields && (Object.keys(formFields).includes('Email') || Object.keys(formFields).includes('Guest'))) {
      setShowModal({ "spinner": 'Saving...' })
      const values = { ...formFields }
      const payload = JSON.stringify({
        action: "loginPreferences",
        values: values
      })
     
      sendMessageToWebsocket(payload)

      const unsubscribeUpdate = subscribe("loginPreferencesResponse", data => {
        setShowModal(null)
        unsubscribeUpdate()
      })
    }
    else {
      setShowError(true)

    }
  }

  const generatePin = () => {
    // generate a random 4 digit pin
    const pin = Math.floor(1000 + Math.random() * 9000)
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    newFieldValues['Guest']['pin'] = pin
    setFormFields(newFieldValues)
  }

  const togglePin = () => {
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    if (formFields['Guest']['pin']){
      delete newFieldValues['Guest']['pin']
    } else {
      generatePin()
    }
    setFormFields(newFieldValues)
  }


  return <Card>
    <h3 className={`text-xl font-bold text-brandblue`}>Login options</h3>



    {tableData && tableData.Organisation && tableData.Organisation.ActiveFeatures && tableData.Organisation.ActiveFeatures.Checklists ?
      <div className={`w-full flex flex-col gap-2 items-start`}>

        <label><input
          className={`mr-1`}
          type={'checkbox'}
          name={'Checklists'}
          value={'cognito'}
          checked={formFields && formFields['Email'] ? true : false}
          onChange={(e: any) => {
            handleCheckbox('Email', e.target.checked)
            setShowError(false)
          }}
        />Login with email</label>

        <label><input
          className={`mr-1`}
          type={'checkbox'}
          name={'Checklists'}
          value={'guest'}
          checked={formFields && formFields['Guest'] ? true : false}
          onChange={(e: any) => {
            handleCheckbox('Guest', e.target.checked)
            setShowError(false)
          }}
        />Guest Logins</label>


        {formFields && formFields['Guest'] && <div className={`w-full flex flex-col gap-3 items-start pb-3`}>

          <div className={`w-full flex flex-col gap-1`}>
            <div className={`flex flex-row gap-2 items-center`}>
              <EnabledDisabled
                handleChange={togglePin}
                enabled={formFields['Guest']['pin']}
                fieldName={''}
              />
             Require a pin with guest login
            </div>

            {formFields && formFields['Guest'] && formFields['Guest']['pin'] && <div className={`w-full flex flex-row gap-2 items-center`}>
              <p className={`font-bold`}>Pin:  #{formFields['Guest']['pin']}</p>
              <Button
                text={formFields['Guest']['pin'] ? `Generate a new pin` : `Generate a pin`}
                onClick={generatePin}
                fullwidth={false}
                size='small'
                variant='gray'
              />
            </div>}
          </div>


          <div className={`flex flex-col gap-1`}>
            <p className={`font-bold`}>Log guests out after:</p>
            <select
              className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 px-3 py-2`}
              onChange={(e) => handleSelect(e.target.value)}
              value={formFields['Guest'] && formFields['Guest']['timeout'] ? formFields['Guest']['timeout'] : ''}
            >
              <option value={''}>Please select</option>
              <option value={'30'}>30 minutes</option>
              <option value={'60'}>One hour</option>
              <option value={'120'}>Two hours</option>
            </select>
          </div>
        </div>}

        {showError && <p className={`text-xs text-red-700`}>Please select at least one option</p>}


        <Button
          onClick={saveData}
          text={'Save'}
          fullwidth={false}
          variant={`primary`}
        // size={`big`}
        />
      </div> :
      <Spinner>Loading...</Spinner>}

  </Card>
}
export default LoginOptions