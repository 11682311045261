export const getChecklistsThatUseThisItem = (keyName, itemId, tableData) => {
    // console.log(`keyName: ${keyName}, itemId: ${itemId}, tableData: ${tableData}`)
    const checklistsThatUseThisItem = []
    if (keyName && itemId && tableData && tableData.Checklists && tableData.ChecklistQuestions) {
        Object.keys(tableData.ChecklistQuestions).forEach(checklistQuestionId => {
            const thisQuestion = tableData.ChecklistQuestions[checklistQuestionId]
            const checklistId = thisQuestion.ChecklistId
            const thisChecklist = tableData.Checklists[checklistId]
            const checklistName = thisChecklist?.ChecklistName || ''
            if (checklistName && thisQuestion[keyName] === itemId && !checklistsThatUseThisItem.find((checklist) => checklist.checklistId === checklistId)) {
                checklistsThatUseThisItem.push({ checklistId, checklistName })
            }
        })
    }
    return checklistsThatUseThisItem
}