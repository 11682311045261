import React, { useEffect, useState, useContext } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom"
import { GlobalContext } from '../../GlobalContext'
import Button from '../../formfields/Button'
import Card from '../../ui-elements/Card'
import RedAlert from '../../ui-elements/RedAlert'
import Spinner from '../../ui-elements/Spinner'
import ChecklistSetupWrapper from '../wrapper/ChecklistSetupWrapper'
import QuestionText from './QuestionText'
import QuestionFormat from './QuestionFormat'
import QuestionOptions from './QuestionOptions'
import SelectFridge from './SelectFridge'
import QuestionInstructions from './QuestionInstructions'
import AnswerActions from './AnswerActions'
import AddButton from '../../formfields/AddButton'
import ParentQuestionDetails from './ParentQuestionDetails'
import RepeatQuestion from './RepeatQuestion'
import SpreadsheetCellReference from './SpreadsheetCellReference'
import MeasurementUnit from './MeasurementUnit'
import SelectCustomDataFormat from './SelectCustomDataFormat'



type Props = {
    questionsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    questionsFormErrors: ObjectStringKeyAnyValue
    setQuestionsFormFields: React.Dispatch<React.SetStateAction<any>>
    setQuestionsFormErrors: React.Dispatch<React.SetStateAction<any>>
}




function ChecklistQuestions({
    questionsFormFields,
    thisChecklist,
    questionsFormErrors,
    setQuestionsFormFields,
    setQuestionsFormErrors
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
        checklistQuestionFields
    } = useContext(GlobalContext)


    const { checklistId, questionId, parentQuestionId, parentActionId } = useParams()
    const [showAnswerActions, setShowAnswerActions] = useState(false)
    const [showCancelAnswerActionsButton, setShowCancelAnswerActionsButton] = useState(false)
    const [showInstructions, setShowInstructions] = useState(false)
    const [showCancelInstructionsButton, setShowCancelInstructionsButton] = useState(false)
    const [showRepeatQuestion, setShowRepeatQuestion] = useState(false)
    const customTemplateFileId = tableData && checklistId && tableData.Checklists && tableData.Checklists[checklistId].CustomTemplateFileId ? tableData.Checklists[checklistId].CustomTemplateFileId : null


    useEffect(() => {
        if (questionsFormFields['QuestionInstructions']) {
            setShowInstructions(true)
        }
        if (questionsFormFields['AnswerActions'] && Object.keys(questionsFormFields['AnswerActions']).length > 0) {
            setShowAnswerActions(true)
        } else {
            setShowAnswerActions(false)
        }
        if (questionsFormFields['RepeatQuestion']) {
            setShowRepeatQuestion(true)
        } else {
            setShowRepeatQuestion(false)
        }
    }, [questionsFormFields])


    const addAnswerAction = () => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...questionsFormFields }
        const newAnswerActionId = `aa-${Date.now()}-${Math.round(Math.random() * 1000)}`
        if (!newFieldValues['AnswerActions']) {
            newFieldValues['AnswerActions'] = {}
        }
        newFieldValues['AnswerActions'][newAnswerActionId] = {}
        setQuestionsFormFields(newFieldValues)
    }




    const cancelInstructions = () => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...questionsFormFields }
        delete newFieldValues['QuestionInstructions']
        setQuestionsFormFields(newFieldValues)
        setShowInstructions(false)
    }

    const cancelAnswerActions = () => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...questionsFormFields }
        delete newFieldValues['AnswerActions']
        setQuestionsFormFields(newFieldValues)
        setShowAnswerActions(false)
    }

    return <div className={`w-full flex flex-col gap-4`}>

        {questionsFormFields['parentQuestionId'] && questionsFormFields['parentActionId'] &&
            <ParentQuestionDetails
                parentQuestionId={questionsFormFields['parentQuestionId']}
                parentActionId={questionsFormFields['parentActionId']}
            />}


        <QuestionFormat
            questionsFormFields={questionsFormFields}
            thisChecklist={thisChecklist}
            questionsFormErrors={questionsFormErrors}
            setQuestionsFormFields={setQuestionsFormFields}
        />

        {
            (
                questionsFormFields['QuestionFormat'] === 'FridgeTemperature' ||
                questionsFormFields['QuestionFormat'] === 'PhotoAndReading'
            )
            &&
            <SelectFridge
                questionsFormFields={questionsFormFields}
                thisChecklist={thisChecklist}
                questionsFormErrors={questionsFormErrors}
                setQuestionsFormFields={setQuestionsFormFields}
            />
        }


        {questionsFormFields['QuestionFormat'] === 'CustomDataFormat' &&
            <SelectCustomDataFormat
                questionsFormFields={questionsFormFields}
                thisChecklist={thisChecklist}
                questionsFormErrors={questionsFormErrors}
                setQuestionsFormFields={setQuestionsFormFields}
            />
        }

        {questionsFormFields['QuestionFormat'] !== 'FridgeTemperature' &&
            questionsFormFields['QuestionFormat'] !== 'PhotoAndReading' &&
            <QuestionText
                questionsFormFields={questionsFormFields}
                thisChecklist={thisChecklist}
                questionsFormErrors={questionsFormErrors}
                setQuestionsFormFields={setQuestionsFormFields}
            />
        }

        {

            questionsFormFields['QuestionFormat'] === 'Number' &&
            <MeasurementUnit
                questionsFormFields={questionsFormFields}
                thisChecklist={thisChecklist}
                questionsFormErrors={questionsFormErrors}
                setQuestionsFormFields={setQuestionsFormFields}
            />
        }

        {(questionsFormFields['QuestionFormat'] === 'SelectMultiple' || questionsFormFields['QuestionFormat'] === 'SelectOne') &&
            <QuestionOptions
                keyname='QuestionOptions'
                questionsFormFields={questionsFormFields}
                thisChecklist={thisChecklist}
                questionsFormErrors={questionsFormErrors}
                setQuestionsFormFields={setQuestionsFormFields}
            />
        }


        {questionsFormFields['QuestionFormat'] === 'MultipleTextFields' &&
            <QuestionOptions
                keyname='QuestionFieldNames'
                questionsFormFields={questionsFormFields}
                thisChecklist={thisChecklist}
                questionsFormErrors={questionsFormErrors}
                setQuestionsFormFields={setQuestionsFormFields}
            />
        }


        {
            showInstructions ? <QuestionInstructions
                questionsFormFields={questionsFormFields}
                thisChecklist={thisChecklist}
                questionsFormErrors={questionsFormErrors}
                setQuestionsFormFields={setQuestionsFormFields}
                cancelInstructions={cancelInstructions}
                showCancelInstructionsButton={showCancelInstructionsButton}
            /> :
                <AddButton
                    onClick={() => {
                        setShowCancelInstructionsButton(true)
                        setShowInstructions(true)
                    }}
                    text={`Add instructions`}
                />
        }

        {

            !questionsFormFields['AnswerActions'] &&
            !questionsFormFields['parentQuestionId'] &&
            !questionsFormFields['parentActionId'] &&
            <>
                {showRepeatQuestion ? <RepeatQuestion
                    questionsFormFields={questionsFormFields}
                    thisChecklist={thisChecklist}
                    questionsFormErrors={questionsFormErrors}
                    setQuestionsFormFields={setQuestionsFormFields}
                    setQuestionsFormErrors={setQuestionsFormErrors}
                /> :
                    <AddButton
                        onClick={() => {
                            setShowRepeatQuestion(true)
                        }}
                        text={`Repeat question`}
                    />}
            </>
        }






        {
            (!questionsFormFields['RepeatQuestion'] || questionsFormFields['RepeatQuestion'].length === 0) &&
            !questionsFormFields['parentQuestionId'] &&
            !questionsFormFields['parentActionId'] &&
            // questionsFormFields['QuestionFormat'] &&
            questionsFormFields['QuestionFormat'] !== 'Photo' &&
            questionsFormFields['QuestionFormat'] !== 'Text' &&
            questionsFormFields['QuestionFormat'] !== 'MultipleTextFields' &&
            <>
                {
                    showAnswerActions ? <AnswerActions
                        questionsFormFields={questionsFormFields}
                        thisChecklist={thisChecklist}
                        questionsFormErrors={questionsFormErrors}
                        setQuestionsFormFields={setQuestionsFormFields}
                        setQuestionsFormErrors={setQuestionsFormErrors}
                        addAnswerAction={addAnswerAction}
                        cancelAnswerActions={cancelAnswerActions}
                        showCancelAnswerActionsButton={showCancelAnswerActionsButton}
                    /> :
                        <AddButton
                            onClick={() => {
                                setShowAnswerActions(true)
                                setShowCancelAnswerActionsButton(true)
                                if (!questionsFormFields['AnswerActions'] || Object.keys(questionsFormFields['AnswerActions']).length === 0) {
                                    addAnswerAction()
                                }
                            }}
                            text={`Add answer actions`}
                        />
                }
            </>}


        {customTemplateFileId && <SpreadsheetCellReference
            questionsFormFields={questionsFormFields}
            thisChecklist={thisChecklist}
            questionsFormErrors={questionsFormErrors}
            setQuestionsFormFields={setQuestionsFormFields}
        />}

        {/* <p className={`text-xs`}>{JSON.stringify(questionsFormFields).replaceAll(',',', ')}</p> */}

    </div >
}
export default ChecklistQuestions