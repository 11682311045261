

import React, { useContext, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import { getDate } from '../utils/getDate'

type Props = {
  questionAnswer: any
  timeZoneString?: string
}

function FormatQuestionAnsweredBy({
  questionAnswer,
  timeZoneString='Europe/London'
}: Props) {

  const {
    tableData,
    currentOrganisation
  } = useContext(GlobalContext)


  return <span>
  {!questionAnswer.CognitoUserId && !questionAnswer.GuestUserName && ` guest user `}
  {!questionAnswer.CognitoUserId && questionAnswer.GuestUserName && ` guest user ${questionAnswer.GuestUserName} `}
  {questionAnswer.CognitoUserId && <>

      {tableData &&
          tableData.Users &&
          tableData.Users[questionAnswer.CognitoUserId] &&
          tableData.Users[questionAnswer.CognitoUserId]['UserName'] ?
          `  ${tableData.Users[questionAnswer.CognitoUserId]['UserName']} ` :
          ` user id: #${questionAnswer.CognitoUserId}`}

  </>}
{}  {questionAnswer.AnswerTimestamp && ` at ${getDate(questionAnswer.AnswerTimestamp, 'formattedDateAndTime', timeZoneString)}`}
</span>
}

export default FormatQuestionAnsweredBy