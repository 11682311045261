import React, { useContext } from 'react'
import { GlobalContext } from '../../GlobalContext'
import RedAlert from '../../ui-elements/RedAlert'


type Props = {
    questionsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    questionsFormErrors: ObjectStringKeyAnyValue
    setQuestionsFormFields: React.Dispatch<React.SetStateAction<any>>
}

function MeasurementUnit({
    questionsFormFields,
    thisChecklist,
    questionsFormErrors,
    setQuestionsFormFields
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)


    const handleChange = (value: string) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...questionsFormFields }
        newFieldValues['MeasurementUnit'] = value

        // delete the text if a fridge temperature is selected
        if (value === `FridgeTemperature`) {
            delete newFieldValues['QuestionText']
        }

        // delete options if a non-multi-option format is selected
        if (value !== `SelectOne` && value !== `SelectMultiple`) {
            delete newFieldValues['QuestionOptions']
        }

        setQuestionsFormFields(newFieldValues)
    }

    return <div className={`flex flex-col gap-2`}>
        <h3 className={`font-righteous text-brandblue text-lg`}>Unit:</h3>
        <select
            className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}

            name={`MeasurementUnit`}
            value={questionsFormFields['MeasurementUnit'] || ''}
            onChange={(e) => handleChange(e.target.value)}

        >
            <option value={``}>No unit </option>
            <optgroup label="Temperature">
                <option value={`°C`}>Degrees celcius (°C)</option>
                <option value={`°F`}>Degrees Fahrenheit (°F)</option>
            </optgroup>
            <optgroup label="Weight">
                <option value={`g`}>Grams (g)</option>
                <option value={`kg`}>Kilograms (kg)</option>
                <option value={`oz`}>Ounces (oz)</option>
                <option value={`lb`}>Pounds (lb)</option>
            </optgroup>
            <optgroup label="Length">
                <option value={`mm`}>Milimetres (mm)</option>
                <option value={`cm`}>Centimeters (cm)</option>
                <option value={`m`}>Meters (m)</option>
                <option value={`km`}>Kilometres (km)</option>
                <option value={`in`}>Inches (in)</option>
                <option value={`ft`}>Feet (ft)</option>
                <option value={`yd`}>Yards (yd)</option>
                <option value={`mi`}>Miles (mi)</option>
            </optgroup>
            <optgroup label="Liquid">
                <option value={`ml`}>Milliliters (ml)</option>
                <option value={`l`}>Liters (l)</option>
                <option value={`pt`}>Pints (pt)</option>
                <option value={`qt`}>Quarts (qt)</option>
                <option value={`gal`}>Gallons (gal)</option>
            </optgroup>



        </select>
        {questionsFormErrors['MeasurementUnit'] && <RedAlert alignment='left'>{questionsFormErrors['MeasurementUnit']}</RedAlert>}
    </div>
}



export default MeasurementUnit