export const getDate = (timestamp, format = 'shortDateAndTime', timeZoneString = 'Europe/London') => {
  const date = new Date(parseInt(timestamp))
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const shortDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ]
  const shortMonths = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]

  const options = { timeZone: timeZoneString, year: 'numeric', month: 'long', day: 'numeric', weekday: 'long', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }
  const formatter = new Intl.DateTimeFormat('en-GB', options)
  const parts = formatter.formatToParts(date)

  const year = parts.find(part => part.type === 'year').value
  const month = parts.find(part => part.type === 'month').value
  const shortMonth = new Intl.DateTimeFormat('en-GB', { month: 'short', timeZone: timeZoneString }).format(date)
  const day = parts.find(part => part.type === 'day').value
  const dayName = parts.find(part => part.type === 'weekday').value
  const shortDayName = new Intl.DateTimeFormat('en-GB', { weekday: 'short', timeZone: timeZoneString }).format(date)
  const hours = parts.find(part => part.type === 'hour').value.padStart(2, '0')
  const minutes = parts.find(part => part.type === 'minute').value.padStart(2, '0')
  const dayAndDate = `${shortDayName} ${day}`
  const fullDayAndDate = `${dayName} ${day}`
  const dayDateMonth = `${shortDayName} ${day} ${shortMonth}`
  const formattedDate = `${shortDayName} ${day}, ${month}, ${year}`
  const formattedDateAndTime = `${shortDayName} ${day} ${month} ${year}, ${hours}:${minutes}`
  const shortDateAndTime = `${shortDayName} ${day}, ${hours}:${minutes}`
  const justHoursAndMinutes = `${hours}:${minutes}`
  const datePickerFormat = `${year}-${`${date.getMonth()+1}`.padStart(2, '0')}-${`${date.getDate()}`.padStart(2, '0')}`



  if (format === 'day') {
    return day
  } else if (format === 'month') {
    return month
  } else if (format === 'year') {
    return year
  } else if (format === 'dayDateMonth') {
    return dayDateMonth
  } else if (format === 'fullDayAndDate') {
    return fullDayAndDate
  } else if (format === 'dayAndDate') {
    return dayAndDate
  } else if (format === 'shortDateAndTime') {
    return shortDateAndTime
  } else if (format === 'formattedDateAndTime') {
    return formattedDateAndTime
  } else if (format === 'formattedDate') {
    return formattedDate
  } else if (format === 'justHoursAndMinutes') {
    return justHoursAndMinutes
  } else if (format === 'datePickerFormat') {
    return datePickerFormat
  } else {
    console.log('🚫 Please specify a format')
    return null
  }
}